import React, { useState, useContext,useEffect,useRef,useLayoutEffect } from 'react';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Row, Col, Container } from 'react-bootstrap';
import { removeUserSession, removeUserStats, userMissingFields } from '../../../functions/manageSessions';
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import Button from '../../button';
import { H1, Span, StyledLink, Label } from '../../theme/typography';
import loadingIndicatorForm from '../loading-indicator-form';
import { GoogleBtnContainer } from  './styles.js';
import { AppContext } from '../../../context';



const GoogleBtn = (props) => {
  const ref = useRef(null);
  const [btnWidth, setBtnWidth] = useState(326); // You don't know the real height yet
  // const [reload, setReload] = useState(true); // You don't know the real height yet

  // const [update, setUpdate] = useState(true); // You don't know the real height yet
  const {userContext} = useContext(AppContext);


  // const handleorientationchange = (event) => {
  //   setUpdate(()=>true);
  // };

    useLayoutEffect(() => {
    //  let { width } = ref.current.getBoundingClientRect();

    if(isMobile ){
      let { width } = ref.current.getBoundingClientRect();
      // window.addEventListener("orientationchange", handleorientationchange);
      setBtnWidth(width);
      // setUpdate(()=>false)
      } 
      // return ()=>{
      //   window.removeEventListener("orientationchange");
      // }
  },[]);

  // useEffect(() => {
  //   // it also happens with reddirection
  //   let element = document.getElementById('google_signin_container');
  //   let width = element.clientWidth;
  //   let height = element.clientHeight;
    
  //   console.log('width',width);
  //   console.log('height',height);
    
  //   if(userContext && height == 0){
  //     // window.location.reload();
  //   }
  // }, [])
  
useEffect(() => {
  
  window.google.accounts.id.initialize({
    client_id: "948003101733-00qi1fj2h81lsqlk8l53ceg8ekdbs8lj.apps.googleusercontent.com",
    auto_prompt:"false",
    callback: (res, error) => {
        // This is the function that will be executed once the authentication with google is finished
        if (res) {
          window.location.replace( `${window.BASE_URL_USER}alpha_bet_login?credential=${res.credential}`);
        };
        if (error) {
          console.log("error", error);
        }
    },
  });
    // window.google.accounts.id.prompt();
  window.google.accounts.id.renderButton(ref.current, {
  theme: 'outline',
  size: 'large',
  type: 'standard',
  text: 'continue_with',
  shape: 'rectangular',
  width: btnWidth,
  });


}, [])


  // window.onload = function () {
  //   window.google.accounts.id.initialize({
  //         client_id: "948003101733-00qi1fj2h81lsqlk8l53ceg8ekdbs8lj.apps.googleusercontent.com",
  //         auto_prompt:"false",
  //         callback: (res, error) => {
  //             // This is the function that will be executed once the authentication with google is finished
  //             if (res) {
  //               console.log('res',res);
  //               const config = {
  //                 method: 'post',
  //                 url: `${window.BASE_URL_USER}alpha_bet_login`,
  //                 withCredentials : true,
  //                 headers: {
  //                   'Content-Type': 'application/json',
  //                 },
  //                 data: { 
  //                         'credential':res.credential},
  //               };
  //               axios(config);
  //             };
  //             if (error) {
  //               console.log("error", error);
  //             }
  //         },
  //       });
  //       // window.google.accounts.id.prompt();
  //   window.google.accounts.id.renderButton(ref.current, {
  //     theme: 'outline',
  //     size: 'large',
  //     type: 'standard',
  //     text: 'continue_with',
  //     shape: 'rectangular',
  //     width: btnWidth,
  //   });
  // };

  // const renderButton =()=>{
  //   console.log('86 rendering');
  //   window.google.accounts.id.renderButton(ref.current, {
  //     theme: 'outline',
  //     size: 'large',
  //     type: 'standard',
  //     text: 'continue_with',
  //     shape: 'rectangular',
  //     width: '326',
  //     click_listener: (e)=>onClickHandler(e),
  //   });
  // };




  // window.onload = function () {
  //   window.google.accounts.id.initialize({
  //     client_id: "948003101733-00qi1fj2h81lsqlk8l53ceg8ekdbs8lj.apps.googleusercontent.com",
  //     auto_prompt:"false",
  //     callback: (res, error) => {
  //         // This is the function that will be executed once the authentication with google is finished
  //         if (res) {
  //           const config = {
  //             method: 'post',
  //             url: `${window.BASE_URL_USER}alpha_bet_login`,
  //             withCredentials : true,
  //             headers: {
  //               'Content-Type': 'application/json',
  //             },
  //             data: {'token': res.credential},
  //           };
  //           axios(config);
  //         };
  //         if (error) {
  //           console.log("error", error);
  //         }
  //     },
  //   });
  //   google.accounts.id.prompt();
  // };
  


  return (
    // pointer-events: none;
    <>
    {typeof btnWidth !=='undefined' && ( 
      
      <GoogleBtnContainer  key={'btn_'+btnWidth}>
        <div  ref={ref} style={{pointerEvents:props.enable?"all":"none"}}></div>
      
        {/* <div 
        style={{pointerEvents:props.enable?"all":"none"}}
        id="g_id_onload"
          data-client_id="948003101733-00qi1fj2h81lsqlk8l53ceg8ekdbs8lj.apps.googleusercontent.com"
          data-context="signin"
          data-ux_mode="popup"
          data-login_uri= {window.BASE_URL_USER +"/alpha_bet_login"} 
          data-auto_prompt="false"
        >
        </div> */}

        {/* <div id='google_signin_container'
        style={{pointerEvents:props.enable?"all":"none"}}
        className="g_id_signin" //TODO CHECK className VS class
          data-type="standard"
          data-shape="rectangular"
          data-theme="outline"
          data-text="continue_with"
          data-size="large"
          data-logo_alignment="left"
          data-width={btnWidth} // not actual size but minimum button width,however it behaves more like width
        >
        </div> */}
      </GoogleBtnContainer>
      
      )}
      </>
  );
};

export default GoogleBtn;
